var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户ID：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "指定客户(不填则为全部)"
                      },
                      model: {
                        value: _vm.queryParams.user_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "user_ids", $$v)
                        },
                        expression: "queryParams.user_ids"
                      }
                    },
                    _vm._l([], function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用ID" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "指定应用(不填则为全部)"
                      },
                      model: {
                        value: _vm.queryParams.app_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "app_ids", $$v)
                        },
                        expression: "queryParams.app_ids"
                      }
                    },
                    _vm._l([], function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户ID" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "指定用户(不填则为全部)"
                      },
                      model: {
                        value: _vm.queryParams.member_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "member_ids", $$v)
                        },
                        expression: "queryParams.member_ids"
                      }
                    },
                    _vm._l([], function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下单/提现时间：", prop: "time_range" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.queryParams.time_range,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "time_range", $$v)
                      },
                      expression: "queryParams.time_range"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "statis-title", attrs: { span: 24 } },
                [_vm._v("客户")]
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.client_total_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("累计佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v("下单时间内有效的客户订单中，累计给客户的佣金")
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(
                          _vm._s(_vm.data.client_waiting_settle_amount || 0)
                        )
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("待结算佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v(
                            "下单时间内有效的客户订单中，未结算给客户的佣金"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.client_tixian_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("提现金额/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v(
                            "指客户提现时间内且已成功的提现总额，其中收取服务费：" +
                              _vm._s(_vm.data.client_base_amount || 0) +
                              "元"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "statis-title", attrs: { span: 24 } },
                [_vm._v("应用")]
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.app_total_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("累计佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v("下单时间内有效的应用订单中，累计给客户的佣金")
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.app_waiting_settle_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("待结算佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v(
                            "下单时间内有效的应用订单中，未结算给客户的佣金"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.app_tixian_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("提现金额/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v(
                            "指应用下所有用户提现时间内且已成功的提现总额，其中收取服务费：" +
                              _vm._s(_vm.data.app_base_amount || 0) +
                              "元 "
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "statis-title", attrs: { span: 24 } },
                [_vm._v("用户")]
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.user_total_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("累计佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v("下单时间内有效的订单中，累计给用户的佣金")
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.user_waiting_settle_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("待结算佣金/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v("下单时间内有效的订单中，未结算给用户的佣金")
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c("div", { staticClass: "statis-data" }, [
                        _vm._v(_vm._s(_vm.data.user_tixian_amount || 0))
                      ]),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("提现金额/元")
                        ]),
                        _c("div", { staticClass: "statis-bottom" }, [
                          _vm._v(
                            "指用户提现时间内且已成功的提现总额，其中收取服务费：" +
                              _vm._s(_vm.data.user_base_amount || 0) +
                              "元"
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }