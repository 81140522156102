import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { financeStatis } from '@/api/users';
export default {
  name: "financeStatis",
  data: function data() {
    return {
      data: {},
      loading: false,
      queryParams: {
        user_ids: [],
        app_ids: [],
        member_ids: [],
        time_range: [],
        start: "",
        end: ""
      }
    };
  },
  methods: {
    requestData: function requestData() {
      var _this = this;

      if (!(this.queryParams.user_ids && this.queryParams.user_ids.length > 0 || this.queryParams.app_ids && this.queryParams.app_ids.length > 0 || this.queryParams.member_ids && this.queryParams.member_ids.length > 0)) {
        return this.$message.warning('客户、应用、用户三者必填一项');
      }

      if (this.queryParams.time_range && this.queryParams.time_range.length > 0) {
        this.queryParams.start = this.queryParams.time_range[0];
        this.queryParams.end = this.queryParams.time_range[1];
      } else {
        return this.$message.warning('请填写时间范围');
      }

      if (this.queryParams.user_ids && this.queryParams.user_ids.length > 0) {
        var ids = [];
        this.queryParams.user_ids.forEach(function (item) {
          ids.push(parseInt(item));
        });
        this.queryParams.user_ids = ids;
      }

      if (this.queryParams.app_ids && this.queryParams.app_ids.length > 0) {
        var _ids = [];
        this.queryParams.app_ids.forEach(function (item) {
          _ids.push(parseInt(item));
        });
        this.queryParams.app_ids = _ids;
      }

      if (this.queryParams.member_ids && this.queryParams.member_ids.length > 0) {
        var _ids2 = [];
        this.queryParams.member_ids.forEach(function (item) {
          _ids2.push(parseInt(item));
        });
        this.queryParams.member_ids = _ids2;
      }

      this.loading = true;
      financeStatis(_objectSpread({}, this.queryParams)).then(function (res) {
        _this.loading = false;

        if (res.code === 0) {
          _this.data = res.data;
        }
      });
    }
  }
};